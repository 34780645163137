import {
    SET_CURRENT_USER,
    LOGOUT_USER,
    UPDATE_CURRENT_USER,
  } from "./userType";

  export const setCurrentUser = (user) => {
    return {
      type: SET_CURRENT_USER,
      payload: user,
    };
  };
  export const UpdateCurrentUser = (user) => {
    return {
      type: UPDATE_CURRENT_USER,
      payload: user,
    };
  };
  export const logout = () => {
    return {
      type: LOGOUT_USER,
      payload: {},
    };
  };