import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import LoadSpinner from '../../Helpers/LoaderSpinner';

const StripeConnectSuccess=()=> {
  const [errorMsg,setErrorMsg]=useState('');
    const [successMsg,setSuccessMsg]=useState('');
    
  const [loading, setLoading] = useState(false)
 // const[req,setReq]=useState(false);
    const [response,setResponse]=useState({});
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const id=queryParams.get('state');
    //alert(id);

    

    useEffect(() => {
        // let flg=false
        // if(!flg){
            verifyOauthToken()
        // flg=true
        //     //setReq(true)
        // }
    }, [])//eslint-disable-line
    

    const verifyOauthToken=async()=>{
      setLoading(true)
        if(code){
        const response=await axios(`/store/verifyOauth/${code}/${id}`)
        const data=response.data;
        setResponse(data);
      
        if(data.success){
           
            setSuccessMsg(data.message);
            setLoading(false)
        }
        else{
            setErrorMsg(data.message)
            setLoading(false)
        }
        

        }
    }


  return (
    <section className="error404">
       <LoadSpinner loading={loading}/>
    <div className="error404__content">
      <img
        src="/images/logos/LocalFete.png"
        alt="Localfete"
        className="error404__image"
      />
      
      {
        response.success?(
            <>
            <h2 className="error404__title"> <h2 className='text-center'>Congratulations!</h2><h3>{successMsg}</h3></h2>
        <Link to="/user/login" className="btn__forms text-white">
          Login <i className="fa-solid fa-arrow-right"></i>
        </Link>
        </>
        ):(
            <>
             <>
             {
              errorMsg?(
                <>
                 <h2 className="error404__title"> <div className='text-center'><h2 className='text-center'>ERROR!</h2>{errorMsg}</div></h2>
        <Link to="/user/login" className="btn__forms text-white">
          OK <i className="fa-solid fa-arrow-right"></i>
        </Link>
                </>
              ):(<></>)
             }
           
        </>
            </>
        )
      }
        
    {/* {
      isSeller?(
        <>
          <h2 className="error404__title"> There is one step missing. Please connect your account with Stripe for receving Fund.</h2>
          <a  href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://localfete.com/stripe/success&client_id=${process.env.REACT_APP_CLIENT_ID}&state={STATE_VALUE}&stripe_user[business_type]=individual&stripe_user[email]=${sellerEmail}&suggested_capabilities[]=transfers`}>
            <img src="/images/stripe.png" alt="stripe" width={250}  />
          </a>
        </>
      ):(
        response.success?(
          
        ):(
          <>
              <h2 className="error404__title">{response.message}</h2>
              </>
        )
      )
      
    
    } */}
      
    </div>
  </section>
  )
}

export default StripeConnectSuccess;