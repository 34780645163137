import React, {lazy} from 'react';
import { useSelector } from 'react-redux';

import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoadSpinner from './Helpers/LoaderSpinner';
import StripeConnectSuccess from './Pages/Seller/StripeConnectSuccess';

const Home=lazy(()=>import('./Pages/Home/Home'));
const Login=lazy(()=>import('./Pages/User/Login'));
const RegisterBuyer=lazy(()=>import('./Pages/User/RegisterBuyer'));
const RegisterSeller=lazy(()=>import('./Pages/User/RegisterSeller'))
const RegisterCouncil=lazy(()=>import('./Pages/User/RegisterCouncil'));
const RecoverPassword=lazy(()=>import('./Pages/User/RecoverPassword'));
const RecoverPasswordMessage=lazy(()=>import('./Pages/User/RecoverPasswordMessage'));
const ChangePassword=lazy(()=>import('./Pages/User/ChangePassword'))
const WelcomeUser=lazy(()=>import('./Pages/User/WelcomeUser'))
const WelcomeCouncil=lazy(()=>import('./Pages/User/WelcomeCouncil'))
const EachProduct=lazy(()=>import('./Pages/Product/EachProduct'))
const Cart=lazy(()=>import('./Pages/Cart/Cart'))
const Checkout=lazy(()=>import('./Pages/Checkout/Checkout'))
const PaymentSuccess=lazy(()=>import('./Pages/Checkout/PaymentSuccess'))
const PaymentError=lazy(()=>import('./Pages/Checkout/PaymentError'))
const AboutUs=lazy(()=>import('./Pages/About/AboutUs'))
const TermsAndConditions=lazy(()=>import('./Pages/About/TermsAndConditions'));
const PrivacyPolicy=lazy(()=>import('./Pages/About/PrivacyPolicy'));
const ReturnPolicy=lazy(()=>import('./Pages/About/ReturnPolicy'))
const BecomeSeller=lazy(()=>import('./Pages/Seller/BecomeSeller'))
const AllStores=lazy(()=>import('./Pages/Store/AllStores'));
const EachStore=lazy(()=>import('./Pages/Store/EachStore'));
const SellerDashboard=lazy(()=>import('./Pages/Seller/SellerDashboard'))
const SellerProfile=lazy(()=>import('./Pages/Seller/SellerProfile'));
const SellerOrders=lazy(()=>import('./Pages/Seller/SellerOrders'));
const MarketingCampaign=lazy(()=>import('./Pages/Seller/MarketingCampaign'))
const SellerDisputes=lazy(()=>import('./Pages/Seller/SellerDisputes'))
const SellerDisputeReply=lazy(()=>import('./Pages/Seller/SellerDisputeDetail'));
const SellerStatusDelivery=lazy(()=>import('./Pages/Seller/SellerStatusDelivery'));
const SellerDownloadOrder=lazy(()=>import('./Pages/Seller/SellerDownloadOrder'));
const SellerStripe=lazy(()=>import('./Pages/Seller/SellerStripeDetail.jsx'))
const SellerEarnings=lazy(()=>import('./Pages/Seller/SellerEarnings'))
const SellerDeleteAccount=lazy(()=>import('./Pages/Seller/SellerDeleteAccount'))
const SellerProducts=lazy(()=>import('./Pages/Seller/SellerProducts'))
const SellerCreateProduct=lazy(()=>import('./Pages/Seller/SellerCreateProduct'))
const SellerActivate=lazy(()=>import('./Pages/Seller/SellerActivate'))
const BuyerDashboard=lazy(()=>import('./Pages/Buyer/BuyerDashboard'));
const BuyerDisputes=lazy(()=>import('./Pages/Buyer/BuyerDisputes'));
const BuyerDisputeDetails=lazy(()=>import('./Pages/Buyer/BuyerDisputeDetails'));
const BuyerOrders=lazy(()=>import('./Pages/Buyer/BuyerOrders'));
const BuyerStatusDelivery=lazy(()=>import('./Pages/Buyer/BuyerStatusDelivery'))
const BuyerDeleteAccount=lazy(()=>import('./Pages/Buyer/BuyerDeleteAccount'))
const BuyerProfile=lazy(()=>import('./Pages/Buyer/BuyerProfile'))
const SellerEditProduct=lazy(()=>import('./Pages/Seller/SellerEditProduct'))
const SubcategoryProducts=lazy(()=>import('./Pages/Product/SubCategoryProducts'));

const AdminDashboard=lazy(()=>import('./Pages/Admin/AdminDashboard'));
const AdminProfile=lazy(()=>import('./Pages/Admin/AdminProfile'))
const AdminBanners=lazy(()=>import('./Pages/Admin/AdminBanners'))
const AdminCategory=lazy(()=>import('./Pages/Admin/AdminCategories'));
const AdminSubCategory=lazy(()=>import('./Pages/Admin/AdminSubCategories'));
const AdminTransactions=lazy(()=>import('./Pages/Admin/AdminTransactions'))
const AdminBuyers=lazy(()=>import('./Pages/Admin/AdminBuyers'))
const AdminRaffles=lazy(()=>import('./Pages/Admin/AdminRaffles'));
const AdminCouncils=lazy(()=>import('./Pages/Admin/AdminCouncils'));
const AdminStores=lazy(()=>import('./Pages/Admin/AdminStores'));
const AdminProducts=lazy(()=>import('./Pages/Admin/AdminProducts'));
const AdminEvents=lazy(()=>import('./Pages/Admin/AdminEvents'));
const AdminCreateEvents=lazy(()=>import('./Pages/Admin/AdminCreateEvent'));
const AdminEditEvent=lazy(()=>import('./Pages/Admin/AdminEditEvent'))
const ProductSearchList=lazy(()=>import('./Pages/Product/ProductSearchList'));
const Event=lazy(()=>import('./Pages/Home/Event'))
const BuyerRewards=lazy(()=>import('./Pages/Buyer/BuyerRewards'))
const BoroughData=lazy(()=>import('./Pages/Home/BoroughData'))
const UserActivate=lazy(()=>import('./Pages/User/UserActivate'));
//const StripeConnectSuccess=lazy(()=>import('./Pages/Seller/StripeConnectSuccess'));
const AppRoutes = () => {
    const isUserLoggedin=useSelector(state=>state.user.isLoggedIn);
   const isAdmin=useSelector(state=>state.user.isAdmin);
    const isSeller=useSelector(state=>state.user.isSeller);
    const isCouncil=useSelector(state=>state.user.isCouncil);
    const isBuyer=isUserLoggedin && !isAdmin && !isSeller;
    return (
        <BrowserRouter>
         <Routes>
          
           <Route path="/" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Home/></React.Suspense>)} />
           <Route path="/account/activate/:token" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><UserActivate/></React.Suspense>)} />

       
           <Route path="/subcategory/:name" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><SubcategoryProducts/></React.Suspense>)} />
           <Route path="/product/search/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><ProductSearchList/></React.Suspense>)} />
           <Route path="/event/:name/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Event/></React.Suspense>)} />
           <Route path="/borough/search/:borough" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><BoroughData/></React.Suspense>)} />

           <Route path="/user/login" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Login/></React.Suspense> )} />
           <Route path="/login" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Login/></React.Suspense> )} />

         <Route path="/user/register" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><RegisterBuyer/></React.Suspense>)} />
         <Route path="/user" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><RegisterBuyer/></React.Suspense>)} />
       
          <Route path="/user/registercouncil" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><RegisterCouncil/></React.Suspense>)} />
           <Route path="/seller/register" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><RegisterSeller/> </React.Suspense>)} />
             <Route path="/user/recoverpassword" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} > <RecoverPassword/> </React.Suspense> )} />
           <Route path="/user/changepassword/:token" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><ChangePassword/></React.Suspense>)} />
           <Route path="/user/updatepassword/:email" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><RecoverPasswordMessage/></React.Suspense>)} />
           <Route path="/user/welcome/:email" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><WelcomeUser/></React.Suspense>)} />
           <Route path="/council/welcome" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><WelcomeCouncil/></React.Suspense>)} />
          
           <Route path="/product/:name/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><EachProduct/></React.Suspense>)} />
           <Route path="/product/:name/:id/:order_id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><EachProduct/></React.Suspense>)} />
          <Route path="/stripe/success" element={<StripeConnectSuccess/>} />
         
            <Route path="/cart" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Cart/></React.Suspense>)} />
           <Route path="/checkout/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><Checkout/></React.Suspense>)} />
           <Route path="/payment/success/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><PaymentSuccess/></React.Suspense>)} />
           <Route path="/payment/failed" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><PaymentError/></React.Suspense>)} />
           <Route path="/about" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><AboutUs/></React.Suspense>)} />
           <Route path="/termsconditions" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><TermsAndConditions/></React.Suspense>)} />
           <Route path="/returnpolicy" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><ReturnPolicy/></React.Suspense>)} />
           <Route path="/privacypolicy" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><PrivacyPolicy/></React.Suspense>)} />
           <Route path="/becomeseller" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><BecomeSeller/></React.Suspense>)} />
           <Route path="/stores" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><AllStores/></React.Suspense>)} />
           <Route path="/store/:name" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} ><EachStore/></React.Suspense>)} />
           <Route path="/seller/activate/:token" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerActivate/>:<Login/>}</React.Suspense>)} />
           
           <Route path="/seller" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isSeller?<SellerDashboard/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/profile" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerProfile/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/profile/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<SellerProfile/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/orders" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerOrders/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/marketing_campaign" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<MarketingCampaign/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/disputes" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerDisputes/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/disputedetail/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerDisputeReply/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/statusdelivery/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerStatusDelivery/>:<Login/>}</React.Suspense>)} />
           <Route path="/orderpdf/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerDownloadOrder/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/stripe" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerStripe/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/earnings" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerEarnings/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/deleteaccount" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerDeleteAccount/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/products" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerProducts/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/createproduct" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerCreateProduct/>:<Login/>}</React.Suspense>)} />
           <Route path="/seller/editproduct/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{(isUserLoggedin && (isSeller || isAdmin))?<SellerEditProduct/>:<Login/>}</React.Suspense>)} />
          
           <Route path="/buyer" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerDashboard/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/orders" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerOrders/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/orderstatus/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerStatusDelivery/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/profile" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerProfile/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/profile/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<BuyerProfile/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/rewards" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerRewards/>:<Login/>}</React.Suspense>)} />
         
           <Route path="/buyer/disputes" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerDisputes/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/disputedetail/:code" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerDisputeDetails/>:<Login/>}</React.Suspense>)} />
           <Route path="/buyer/deleteaccount" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isBuyer?<BuyerDeleteAccount/>:<Login/>}</React.Suspense>)} />
          

           <Route path="/admin" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminDashboard/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/profile" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminProfile/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/categories" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminCategory/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/subcategories" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminSubCategory/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/banners" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminBanners/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/transactions" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminTransactions/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/raffles" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminRaffles/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/buyers" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminBuyers/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/councils" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && isAdmin?<AdminCouncils/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/stores" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminStores/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/products" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminProducts/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/events" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminEvents/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/eventcreate" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminCreateEvents/>:<Login/>}</React.Suspense>)} />
           <Route path="/admin/editevent/:id" element={(<React.Suspense fallback={<LoadSpinner loading={true}/>} >{isUserLoggedin && (isAdmin || isCouncil)?<AdminEditEvent/>:<Login/>}</React.Suspense>)} />
         

        </Routes>
        </BrowserRouter>
       
    );
}

export default AppRoutes;
