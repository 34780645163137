import {createStore,applyMiddleware,combineReducers,compose} from 'redux';
//import passReducer from './reducer/passReducer';
import userReducer from './reducer/userReducer';
const thunkMiddleware =require('redux-thunk').default;
// creating a redux store by combining all the reducers
const mainReducer=combineReducers(
    {
        user:userReducer
    }
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store=createStore(mainReducer,composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;