import axios from "axios";

export const decodeJwt=async(token)=>{
    const data=await axios(`/user/decode/${token}`);
    return data.data;
}

export const verifyJwt=async(token)=>{
    const response=await axios(`/user/verify/${token}`);
    return response.data;
}