
import AppRoutes from "./Routes";
import store from './redux/store'
import setAuthenticationToken from "./redux/action/setAuthenticationToken";
import { logout, setCurrentUser } from "./redux/action/userAction";
import { decodeJwt, verifyJwt } from "./Helpers/JwtVerify";
import { useEffect } from "react";

function App() {

  const verifyToken=async()=>{
    if(localStorage.token){
      setAuthenticationToken(localStorage.token);
     const isValid=await verifyJwt(localStorage.token);
     if(isValid){
      store.dispatch(setCurrentUser(await decodeJwt(localStorage.token)));
     }else{
      store.dispatch(logout());
     }
    }
  }
  useEffect(() => {
   verifyToken()
  }, [])
 
  
  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
