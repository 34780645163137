import React from 'react'

const LoadSpinner = ({loading}) => {
  
  return (
    <div className={loading?"load d-block":"d-none"}>
        <img src="/images/logos/LocalFeteMobile.png" alt="Localfete" className='load__image' />
  <div className="load__spinner" />
</div>
  )
}

export default LoadSpinner