import {
    SET_CURRENT_USER,
    UPDATE_CURRENT_USER,
    LOGOUT_USER,
  } from "../action/userType";

  const initialState = {
    isLoggedIn: false,
    isAdmin:false,
    isCouncil:false,
    isSeller:false,
    isVerified:false,
    user_id: "",
    action: "",
    msg: "",
    userDetails: {},
  };

  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      
      case SET_CURRENT_USER:
        
        return {
          ...state,
          userDetails: action.payload,
          isLoggedIn: true,
          user_id: action.payload?.userData?._id,
          isAdmin:action.payload?.userData?.is_admin,
          isCouncil:action.payload?.userData?.is_council,
          isVerified:action.payload?.userData?.is_verified,
          isSeller:action.payload?.userData?.is_seller,
        };
      case UPDATE_CURRENT_USER:
        
        return {
          ...state,
          userDetails: action.payload,
          // user_id: action.payload.userData._id,
        };
      case LOGOUT_USER:
        localStorage.removeItem('token')
        return {
          ...state,
          isLoggedIn: false,
          userDetails: {},
          user_id: "",
        };
      
  
      default:
        return state;
    }
  };
  
  export default userReducer;